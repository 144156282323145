import React from "react";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import { AuthProvider } from "../../../../components/Auth/auth";
import DashboardLayout from "../../../../components/DashboardLayout";
import SimpleSpring from "../../../../components/Transitions/transitions";
import AuctionCreationNav from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/internal/AuctionCreationNav";
import AuctionSuccess from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/steps/AuctionSuccess";
import { useAppDispatch } from "../../../../store";
import { resetAuctions } from "../../../../store/auctions/reducer";
import { resetAuctionModalState } from "../../../../store/auctionModal/reducer";
import {
  getAuction,
  getCurrentPath,
} from "../../../../store/auctionModal/selectors";
import { Auction } from "../../../../types/Auction";
import { PathTypes } from "../../../../components/PageComponents/Dashboard/Auctions/NewAuctionCreation/Types/PathTypes";
import { MethodTypes } from "../../../../enums/MethodTypes";

const AuctionCreateStep9 = ({ location }) => {
  const dispatch = useAppDispatch();
  const path = useSelector((state) => getCurrentPath(state));

  const auction = useSelector((state) => getAuction(state)) as Auction;
  const navigateBack = () => {
    dispatch(resetAuctions());
    dispatch(resetAuctionModalState());
    if (path === PathTypes.AUCTIONS) {
      navigate("/dashboard/auctions/");
    } else {
      navigate(`/dashboard/`);
    }
  };

  return (
    <AuthProvider>
      <SimpleSpring location={location}>
        {auction && (
          <DashboardLayout
            hideFooter
            style={{ paddingTop: 0 }}
            hideNav
            title="Bidddy | Create New Auction - Confirmation"
          >
            <AuctionCreationNav
              isConfirmation
              backTitle="Done"
              onBack={() => navigateBack()}
              step={auction.type === MethodTypes.code ? 3 : 5}
              totalSteps={auction.type === MethodTypes.code ? 4 : 6}
              title="Confirmation"
            />
            <AuctionSuccess />
          </DashboardLayout>
        )}
      </SimpleSpring>
    </AuthProvider>
  );
};

export default AuctionCreateStep9;
